import moment from 'moment';
import { isNil, capitalize } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Page, Text, Svg, Path, View, Document, PDFDownloadLink } from '@react-pdf/renderer';
import { Button, Icon } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import {
  page,
  wrap,
  date,
  wrappedContainer,
  bookingNumberContainer,
  bookingLabel,
  bookingValue,
  invoiceSectionTitle,
  sides,
  invoiceButton,
} from './styles';

const InvoicePdf = ({ invoiceInfo }) => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const hasUserNames =
    (invoiceInfo?.bookingUser?.firstName !== '' && !isNil(invoiceInfo?.bookingUser?.firstName)) ||
    (invoiceInfo?.bookingUser?.lastName !== '' && !isNil(invoiceInfo?.bookingUser?.lastName));

  return (
    <PDFDownloadLink
      fileName={`${invoiceInfo?.hotelName} ${invoiceInfo?.hotelId}.pdf`}
      document={
        <Document title={`${invoiceInfo?.hotelName}`}>
          <Page size="A4" style={page}>
            <View style={date}>
              <Text>{moment(invoiceInfo?.paymentDate).format('D MMM YYYY')}</Text>
            </View>
            <View style={wrappedContainer}>
              <Svg width="173" height="45" viewBox="0 0 173 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <Path
                  d="M40.5 26.9315L44.9609 22.4707L40.5 18.0196V6.5837H38.3772V4.46087H26.9413L22.4805 0L18.0196 4.46087H6.5837V6.5837H4.46087V18.0196L0 22.4804L4.46087 26.9413V38.3772H6.5837V40.5H18.0196L22.4805 44.9609L26.9413 40.5H38.3772V38.3772H40.5V26.9315ZM44.1979 22.4804L40.5 26.1783V18.7728L44.1979 22.4804ZM28.8392 37.8392H16.1218L7.12174 28.8391V16.112L16.1218 7.11196H28.8489L37.8489 16.112V28.8391L28.8392 37.8392ZM37.8392 29.5924V37.8294H29.6022L37.8392 29.5924ZM28.3109 38.3674L26.7163 39.962H18.2446L16.65 38.3674H28.3109ZM15.3587 37.8392H7.12174V29.6022L15.3587 37.8392ZM6.5837 28.3011L4.98913 26.7065V18.2348L6.5837 16.6402V28.3011ZM7.12174 15.3587V7.12174H15.3587L7.12174 15.3587ZM16.65 6.5837L18.2446 4.98913H26.7163L28.3109 6.5837H16.65ZM29.6022 7.12174H37.8392V15.3587L29.6022 7.12174ZM38.3772 16.65L39.9718 18.2446V26.7163L38.3772 28.3109V16.65ZM39.962 7.12174V17.4913L38.3674 15.8967V7.12174H39.962ZM37.8392 4.99892V6.59348H29.0642L27.4696 4.99892H37.8392ZM22.4805 0.753261L26.1783 4.45109H18.7826L22.4805 0.753261ZM7.12174 4.99892H17.4913L15.8968 6.59348H7.12174V4.99892ZM4.99892 7.12174H6.59348V15.8967L4.99892 17.4815V7.12174ZM0.763043 22.4804L4.46087 18.7826V26.1881L0.763043 22.4804ZM4.99892 37.8392V27.4696L6.59348 29.0641V37.8392H4.99892ZM7.12174 39.962V38.3674H15.8968L17.4913 39.962H7.12174ZM22.4805 44.1979L18.7826 40.5H26.1881L22.4805 44.1979ZM37.8392 39.962H27.4696L29.0642 38.3674H37.8392V39.962ZM38.3772 37.8392V29.0641L39.9718 27.4696V37.8392H38.3772Z"
                  fill="#0A1632"
                />
                <Path
                  d="M36.5653 17.9422C36.624 18.2552 36.5653 18.5781 36.5262 18.7346C36.5066 18.8031 36.4773 18.8618 36.4186 18.9107C36.1447 19.165 35.2447 19.84 34.9414 20.0161L20.1599 27.9205C19.5631 28.2629 18.2131 28.5172 17.4697 28.2237C17.3914 28.1944 17.3425 28.1357 17.3034 28.0574C16.6479 26.6976 17.1273 25.0248 18.3697 24.3107L29.3164 18.0302C30.4805 17.365 31.7914 17.0911 33.1023 17.2574L36.2718 17.6585C36.4186 17.6683 36.5262 17.7857 36.5653 17.9422Z"
                  fill="#0A1632"
                />
                <Path
                  d="M18.7311 27.3425L17.9681 28.1055C17.7822 28.2914 17.4887 28.2816 17.3126 28.0762L14.2018 24.3588C14.0257 24.1533 14.0452 23.8305 14.2311 23.6446C14.8474 23.0381 15.7963 23.0773 16.3539 23.7523L19.0148 26.3544C19.1811 26.5599 18.917 27.1566 18.7311 27.3425Z"
                  fill="#0A1632"
                />
                <Path
                  d="M27.6337 22.3147L26.6946 23.2538C26.3032 23.6452 25.6967 23.6158 25.3445 23.1854L19.3674 16.0343C19.0054 15.6038 19.0348 14.9484 19.4261 14.5571C20.1695 13.8136 20.8837 13.9408 21.9989 14.6647C22.0185 14.6745 22.038 14.6941 22.0576 14.7038L29.15 20.2897C29.3554 20.5441 27.8685 22.0799 27.6337 22.3147Z"
                  fill="#0A1632"
                />
                <Path
                  d="M27.8388 24.2031C27.8388 24.2031 15.1703 32.7433 11.2181 29.9749C10.1029 29.1923 9.92684 27.451 9.81923 27.5292C9.71162 27.6075 10.054 29.4662 11.3942 30.6792C13.1453 32.2542 16.5595 30.9629 19.0932 29.8086C21.4019 28.7423 27.8388 24.2031 27.8388 24.2031Z"
                  fill="#0A1632"
                />
                <Path
                  d="M26.283 25.4644C26.283 25.4644 16.7254 34.5524 13.0863 32.5372C12.0591 31.9698 11.6972 30.4339 11.6189 30.5122C11.5309 30.6002 12.0493 32.2241 13.3211 33.1437C14.9743 34.347 17.6743 32.8013 19.6602 31.4807C21.4407 30.2774 26.283 25.4644 26.283 25.4644Z"
                  fill="#0A1632"
                />
                <Path
                  d="M30.5427 17.021C31.384 16.7764 32.2742 16.6982 33.1546 16.8156L34.2111 16.9525C32.1959 12.609 27.8818 9.57643 22.8535 9.42969C22.8535 9.42969 22.8535 9.42969 22.8535 10.2612V10.5058V10.8775C26.2187 11.0732 29.1046 13.3721 30.5427 17.021Z"
                  fill="#0A1632"
                />
                <Path
                  d="M35.162 20.3961L31.5326 22.3331C31.5326 22.382 31.5326 22.4309 31.5326 22.4798C31.5326 29.1809 27.6881 33.8081 22.8652 34.082V35.5298C29.8402 35.3244 35.4359 29.5624 35.4359 22.4798C35.4359 21.7461 35.3772 21.032 35.2598 20.3374C35.2207 20.357 35.1913 20.3765 35.162 20.3961Z"
                  fill="#0A1632"
                />
                <Path
                  d="M15.3989 29.7971C14.2836 28.0851 13.5793 25.9036 13.4521 23.2917V21.6482C13.726 15.7982 16.9543 12.1003 21.1021 10.9656C21.3369 10.8971 21.5326 11.0536 21.5423 11.2982V11.7677V12.609H22.4326V11.7775V9.45903L22.1 9.42969C15.125 9.63512 9.5293 15.3971 9.5293 22.4797C9.5293 25.1797 10.3413 27.684 11.7304 29.7677C12.6108 30.208 13.9021 30.159 15.3989 29.7971Z"
                  fill="#0A1632"
                />
                <Path
                  d="M16.9729 31.6848C16.836 31.5576 16.699 31.4207 16.562 31.2739C15.5447 31.6065 14.5273 31.8315 13.5979 31.8315C13.549 31.8315 13.5001 31.8315 13.4512 31.8315C13.6468 32.0174 13.8425 32.2033 14.0479 32.3794C14.8501 32.4674 15.8675 32.1935 16.9729 31.6848Z"
                  fill="#0A1632"
                />
                <Path
                  d="M21.5408 32.3394V33.1807V33.6502C21.5408 33.8948 21.3451 34.0513 21.1005 33.9828C20.1321 33.7187 19.2223 33.3176 18.3908 32.7894C17.6179 33.2296 16.8255 33.6013 16.0625 33.8263C17.8527 34.8633 19.9071 35.4796 22.1082 35.5383L22.4408 35.5089V33.1904V32.3589H21.5408V32.3394Z"
                  fill="#0A1632"
                />
                <Path
                  d="M55.2446 28.4601C54.1673 27.462 53.6445 26.0837 53.6445 24.341V20.6656C53.6445 18.9229 54.1832 17.5446 55.2446 16.5465C56.3061 15.5485 57.8269 15.0415 59.7914 15.0415C61.74 15.0415 63.2609 15.5485 64.3382 16.5465C65.4155 17.5446 65.9541 18.9229 65.9541 20.6656V24.341C65.9541 26.0837 65.4155 27.462 64.3382 28.4601C63.2609 29.4582 61.7559 29.9651 59.7914 29.9651C57.8269 29.9651 56.3061 29.4582 55.2446 28.4601ZM61.4073 25.9411C61.7876 25.6084 61.9777 25.1332 61.9777 24.4995V20.5072C61.9777 19.8735 61.7876 19.3982 61.4073 19.0655C61.0271 18.7328 60.4885 18.5744 59.7914 18.5744C59.0785 18.5744 58.5399 18.7328 58.1596 19.0655C57.7794 19.3982 57.5893 19.8735 57.5893 20.5072V24.4995C57.5893 25.1332 57.7794 25.6084 58.1596 25.9411C58.5399 26.2738 59.0785 26.4323 59.7914 26.4323C60.4885 26.4323 61.0271 26.2738 61.4073 25.9411Z"
                  fill="#0A1632"
                />
                <Path
                  d="M67.9165 29.4736C67.7897 29.3468 67.7422 29.125 67.7422 28.824V16.1976C67.7422 15.8807 67.8056 15.6748 67.9165 15.548C68.0432 15.4213 68.265 15.3579 68.5818 15.3579H70.9424C71.1483 15.3579 71.3226 15.3896 71.4493 15.453C71.5761 15.5163 71.687 15.6114 71.782 15.7698L75.0773 20.9503C75.3783 21.4097 75.6001 21.8216 75.7743 22.1702C75.9486 22.5187 76.1387 22.994 76.3605 23.596C76.408 23.7227 76.4556 23.8653 76.5189 24.0238C76.5823 24.198 76.6615 24.3881 76.7566 24.6258H76.915C76.5665 23.5009 76.3288 22.5979 76.2021 21.9484C76.0753 21.2988 76.012 20.4909 76.012 19.5403V16.2134C76.012 15.8966 76.0753 15.6906 76.1862 15.5639C76.313 15.4371 76.5348 15.3737 76.8516 15.3737H78.8953C79.1963 15.3737 79.4181 15.4371 79.5448 15.5639C79.6716 15.6906 79.735 15.9124 79.735 16.2134V28.8399C79.735 29.1567 79.6716 29.3627 79.5448 29.4894C79.4181 29.6161 79.1963 29.6795 78.8953 29.6795H76.6298C76.4239 29.6795 76.2654 29.6478 76.1387 29.5845C76.012 29.5211 75.9011 29.426 75.806 29.2676L72.7167 24.4673C72.3048 23.802 71.9721 23.1999 71.7503 22.6296C71.5127 22.0593 71.2592 21.2988 70.9582 20.3325H70.7998C71.0533 21.4731 71.2275 22.3761 71.3226 23.0257C71.4176 23.6752 71.4652 24.5149 71.4652 25.5446V28.8399C71.4652 29.1567 71.4018 29.3627 71.2751 29.4894C71.1483 29.6161 70.9265 29.6795 70.6097 29.6795H68.5818C68.2491 29.6637 68.0274 29.6003 67.9165 29.4736Z"
                  fill="#0A1632"
                />
                <Path
                  d="M82.0356 29.4736C81.9089 29.3468 81.8613 29.125 81.8613 28.824V16.1976C81.8613 15.8807 81.9247 15.6748 82.0356 15.548C82.1623 15.4213 82.3841 15.3579 82.701 15.3579H91.0975C91.4143 15.3579 91.6361 15.4213 91.7629 15.548C91.8896 15.6748 91.953 15.8966 91.953 16.1976V18.0194C91.953 18.3363 91.8896 18.5581 91.7629 18.6848C91.6361 18.8116 91.4143 18.8591 91.0975 18.8591H85.8061V20.6651H90.0361C90.3529 20.6651 90.5747 20.7285 90.7014 20.8553C90.8282 20.982 90.8915 21.2038 90.8915 21.5365V23.3267C90.8915 23.6435 90.8282 23.8812 90.7014 24.0079C90.5747 24.1346 90.3529 24.198 90.0361 24.198H85.8061V26.1308H91.2718C91.5728 26.1308 91.7946 26.1942 91.9213 26.3051C92.048 26.4318 92.1114 26.6536 92.1114 26.9704V28.8082C92.1114 29.125 92.048 29.331 91.9213 29.4577C91.7946 29.5845 91.5728 29.6478 91.2718 29.6478H82.6851C82.3683 29.6637 82.1465 29.6003 82.0356 29.4736Z"
                  fill="#0A1632"
                />
                <Path
                  d="M103.294 29.364L97.9397 15.6444C97.9238 15.6127 97.9238 15.581 97.9238 15.5493C97.9238 15.4543 97.9872 15.4067 98.1298 15.4067H98.51C98.6526 15.4067 98.7635 15.4226 98.8269 15.4543C98.8902 15.4859 98.9219 15.5335 98.9536 15.6285L103.374 27.1143C103.437 27.2886 103.5 27.447 103.548 27.6055C103.595 27.7639 103.643 27.8906 103.69 28.0174L103.833 28.4609H103.881C103.928 28.3342 104.007 28.1124 104.102 27.8272C104.166 27.5738 104.245 27.3361 104.324 27.1302L108.728 15.6444C108.76 15.5493 108.792 15.486 108.855 15.4701C108.919 15.4384 109.014 15.4226 109.172 15.4226H109.552C109.726 15.4226 109.774 15.5018 109.711 15.6602L104.34 29.3481C104.293 29.5065 104.166 29.5858 104.007 29.5858H103.611C103.437 29.5858 103.326 29.5224 103.294 29.364Z"
                  fill="#0A1632"
                />
                <Path
                  d="M112.849 28.5555C111.915 27.6842 111.455 26.4802 111.455 24.9276V20.064C111.455 18.5272 111.915 17.3074 112.849 16.436C113.784 15.5647 115.115 15.1211 116.842 15.1211C118.568 15.1211 119.899 15.5647 120.818 16.436C121.737 17.3074 122.212 18.5114 122.212 20.064V24.9434C122.212 26.4802 121.753 27.7 120.818 28.5714C119.899 29.4427 118.568 29.8863 116.842 29.8863C115.115 29.8704 113.784 29.4269 112.849 28.5555ZM120.153 27.9377C120.913 27.2248 121.293 26.2108 121.293 24.9276V20.0956C121.293 18.8124 120.913 17.7985 120.153 17.0856C119.392 16.3727 118.283 16.0083 116.842 16.0083C115.4 16.0083 114.307 16.3727 113.53 17.0856C112.77 17.7985 112.39 18.8124 112.39 20.0956V24.9276C112.39 26.2267 112.77 27.2248 113.53 27.9377C114.291 28.6506 115.4 29.015 116.842 29.015C118.283 29.015 119.376 28.6506 120.153 27.9377Z"
                  fill="#0A1632"
                />
                <Path
                  d="M129.228 29.5216C129.196 29.4741 129.18 29.4107 129.18 29.3157V23.7233L123.794 15.6595C123.762 15.6119 123.746 15.5644 123.746 15.5327C123.746 15.4377 123.809 15.3901 123.92 15.3901H124.443C124.586 15.3901 124.697 15.406 124.76 15.4377C124.823 15.4693 124.871 15.5169 124.934 15.6119L129.671 22.7727L134.408 15.6119C134.456 15.5169 134.519 15.4535 134.582 15.4377C134.646 15.406 134.741 15.3901 134.899 15.3901H135.406C135.501 15.3901 135.549 15.4218 135.58 15.4693C135.596 15.5169 135.596 15.5802 135.549 15.6436L130.131 23.755V29.2998C130.131 29.3949 130.115 29.4583 130.083 29.5058C130.051 29.5533 129.988 29.5692 129.893 29.5692H129.449C129.323 29.585 129.259 29.5692 129.228 29.5216Z"
                  fill="#0A1632"
                />
                <Path
                  d="M135.076 29.348L140.605 15.5492C140.653 15.3907 140.763 15.3115 140.938 15.3115H141.318C141.413 15.3115 141.476 15.3274 141.54 15.359C141.587 15.3907 141.635 15.4541 141.666 15.5492L147.195 29.348C147.227 29.3955 147.243 29.443 147.243 29.4747C147.243 29.5539 147.164 29.6014 147.021 29.6014H146.657C146.514 29.6014 146.403 29.5856 146.34 29.5539C146.277 29.5222 146.229 29.4747 146.197 29.3796L144.439 24.9596H137.817L136.058 29.3796C136.011 29.4747 135.963 29.5381 135.916 29.5539C135.852 29.5856 135.757 29.6014 135.599 29.6014H135.266C135.06 29.5856 134.997 29.5064 135.076 29.348ZM141.318 17.1176C141.255 16.9116 141.207 16.7057 141.175 16.5314C141.144 16.3571 141.128 16.2621 141.128 16.2304H141.064C141.064 16.2621 141.049 16.3571 141.033 16.5472C141.017 16.7215 140.969 16.9116 140.906 17.1017L138.086 24.1833H144.154L141.318 17.1176Z"
                  fill="#0A1632"
                />
                <Path
                  d="M150.379 28.5561C149.444 27.6847 148.984 26.4649 148.984 24.9281V20.0645C148.984 18.5278 149.444 17.3237 150.379 16.4524C151.297 15.5811 152.644 15.1533 154.387 15.1533C155.892 15.1533 157.048 15.4385 157.888 16.0088C158.712 16.5791 159.298 17.4505 159.631 18.6228C159.662 18.702 159.678 18.7496 159.678 18.7654C159.678 18.8129 159.662 18.8446 159.615 18.8763C159.567 18.908 159.535 18.9397 159.472 18.9714L159.092 19.0981C159.044 19.1139 159.013 19.1139 158.949 19.1139C158.902 19.1139 158.854 19.0981 158.823 19.0664C158.791 19.0347 158.775 18.9872 158.743 18.9238C158.458 17.9099 157.967 17.1812 157.302 16.7059C156.636 16.2464 155.654 16.0088 154.403 16.0088C152.945 16.0088 151.836 16.3573 151.076 17.0703C150.331 17.7832 149.951 18.7654 149.951 20.0486V24.944C149.951 26.2431 150.331 27.2411 151.091 27.954C151.852 28.667 152.961 29.0313 154.403 29.0313C155.828 29.0313 156.921 28.6828 157.698 27.9699C158.474 27.257 158.854 26.3064 158.854 25.0707V23.328H154.577C154.418 23.328 154.339 23.2488 154.339 23.0746V22.7102C154.339 22.5359 154.418 22.4567 154.577 22.4567H159.504C159.599 22.4567 159.662 22.4725 159.71 22.5201C159.757 22.5676 159.773 22.631 159.773 22.7419V25.0707C159.773 26.5441 159.314 27.7164 158.379 28.5877C157.444 29.4591 156.129 29.8868 154.403 29.8868C152.644 29.871 151.313 29.4274 150.379 28.5561Z"
                  fill="#0A1632"
                />
                <Path
                  d="M163.114 29.5216C163.082 29.4741 163.066 29.4107 163.066 29.3157V15.6595C163.066 15.5644 163.082 15.501 163.114 15.4535C163.146 15.406 163.209 15.3901 163.304 15.3901H171.463C171.558 15.3901 171.621 15.406 171.669 15.4535C171.716 15.501 171.732 15.5644 171.732 15.6595V16.0238C171.732 16.1823 171.653 16.2615 171.479 16.2615H163.985V21.9489H170.592C170.687 21.9489 170.75 21.9648 170.798 22.0123C170.845 22.0598 170.861 22.1232 170.861 22.2182V22.5826C170.861 22.6777 170.845 22.7411 170.813 22.7886C170.782 22.8361 170.702 22.8519 170.607 22.8519H164.001V28.7453H171.764C171.859 28.7453 171.922 28.7612 171.954 28.7929C171.986 28.8246 172.002 28.8879 172.002 28.983V29.3474C172.002 29.4424 171.986 29.5058 171.954 29.5533C171.922 29.6008 171.859 29.6167 171.764 29.6167H163.32C163.209 29.585 163.146 29.5692 163.114 29.5216Z"
                  fill="#0A1632"
                />
              </Svg>
              <View style={bookingNumberContainer}>
                <Text style={bookingLabel}>{getText('bookingNumber')}</Text>
                <Text style={bookingValue}>{invoiceInfo?.bookingId}</Text>
              </View>
            </View>
            <View style={invoiceSectionTitle}>
              <Text>{getText('yourDetails').toUpperCase()}</Text>
            </View>
            {hasUserNames && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('name')}</Text>
                </View>
                <View style={sides}>
                  <Text
                    style={
                      bookingValue
                    }>{`${invoiceInfo?.bookingUser?.firstName} ${invoiceInfo?.bookingUser?.lastName}`}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.bookingUser?.email) && invoiceInfo?.bookingUser?.email !== '' && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('emailAddress')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.bookingUser?.email}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.priceDetails?.paymentDate) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('date')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>
                    {moment(invoiceInfo?.priceDetails?.paymentDate)?.format('D MMM YYYY')}
                  </Text>
                </View>
              </View>
            )}
            <View style={invoiceSectionTitle}>
              <Text>{getText('bookingDetails').toUpperCase()}</Text>
            </View>
            {!isNil(invoiceInfo?.hotelName) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('propertyName')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.hotelName}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.roomDetails) && invoiceInfo?.roomDetails?.fareType !== '' && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('propertyFareType')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.roomDetails?.fareType}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.roomDetails) && invoiceInfo?.roomDetails?.description !== '' && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('propertyDescription')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.roomDetails?.description}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.roomDetails) && invoiceInfo?.roomDetails?.boardType !== '' && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('propertyBoardType')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.roomDetails?.boardType}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.roomDetails) &&
              !isNil(invoiceInfo?.roomDetails?.maxOccupancy) &&
              invoiceInfo?.roomDetails?.maxOccupancy !== '' && (
                <View style={wrap}>
                  <View style={sides}>
                    <Text style={bookingLabel}>{getText('propertyMaxOccupancy')}</Text>
                  </View>
                  <View style={sides}>
                    <Text style={bookingValue}>{invoiceInfo?.roomDetails?.maxOccupancy}</Text>
                  </View>
                </View>
              )}
            {!isNil(invoiceInfo?.address) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('propertyAddress')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>
                    {`${!isNil(invoiceInfo?.address?.address) ? invoiceInfo?.address?.address : ''} ${
                      !isNil(invoiceInfo?.address?.cityName) ? `, ${invoiceInfo?.address?.cityName}` : ''
                    } ${!isNil(invoiceInfo?.address?.stateName) ? `, ${invoiceInfo?.address?.stateName}` : ''} ${
                      !isNil(invoiceInfo?.address?.countryName) ? `, ${invoiceInfo?.address?.countryName}` : ''
                    }`}
                  </Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.bookingId) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('bookingNumber')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.bookingId}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.priceDetails?.paymentMethod) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('paymentMethod')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{invoiceInfo?.priceDetails?.paymentMethod}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.priceDetails?.cryptoPrice) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{capitalize(getText('cryptoPrice'))}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>
                    {printPrice({
                      crypto: Number(invoiceInfo?.priceDetails?.cryptoPrice),
                      ignoreEmpty: true,
                    })}
                  </Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.checkIn) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('checkIn')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{moment(invoiceInfo?.checkIne).format('D MMM YYYY')}</Text>
                </View>
              </View>
            )}
            {!isNil(invoiceInfo?.checkOut) && (
              <View style={wrap}>
                <View style={sides}>
                  <Text style={bookingLabel}>{getText('checkOut')}</Text>
                </View>
                <View style={sides}>
                  <Text style={bookingValue}>{moment(invoiceInfo?.checkOut).format('D MMM YYYY')}</Text>
                </View>
              </View>
            )}
            <View style={wrap}>
              {!isNil(invoiceInfo?.priceDetails?.paymentDate) && (
                <View style={sides}>
                  <Text style={bookingLabel}>
                    {getText('amountOn', { date: moment(invoiceInfo?.priceDetails?.paymentDate).format('D MMM YYYY') })}
                  </Text>
                </View>
              )}
              {!isNil(invoiceInfo?.priceDetails?.pricePaid) && !isNil(invoiceInfo?.priceDetails?.cryptoPrice) && (
                <View style={sides}>
                  <Text style={bookingValue}>
                    {printPrice({
                      fiat: Number(invoiceInfo?.priceDetails?.pricePaid),
                      crypto: Number(invoiceInfo?.priceDetails?.cryptoPrice),
                      fiatOriginCurrency: DEFAULT_CURRENCY.code,
                      fiatDesiredCurrency: selectedCurrency?.code,
                    })}
                  </Text>
                </View>
              )}
            </View>
          </Page>
        </Document>
      }>
      <Button type="secondary" css={invoiceButton} onClick={() => {}}>
        <Icon material iconName="download" className="icon-download" size={24} />
        {getText('invoice')}
      </Button>
    </PDFDownloadLink>
  );
};

export default InvoicePdf;
