import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { add, mul } from 'exact-math';
// REMOVE TAG HIDE FOR FIRST VERSION
// import moment from 'moment';
import { pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Icon, Button, ColorBatch, PriceLabel, showApiError } from 'components';
import { ROUTES, DEFAULT_CURRENCY } from 'enums';
import { useUserStore } from 'stores';
import { getConfig } from 'services';
import { roomRateContainer } from './styles';

const RoomPriceCart = ({ room, expand, onExpand, hotelDetails, filters }) => {
  const [config, setConfig] = useState({});
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const { userInfo, isAuthenticated } = useUserStore((userState) => pick(userState, ['userInfo', 'isAuthenticated']));
  const { isAuthenticated } = useUserStore((userState) => pick(userState, ['isAuthenticated']));
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const { getText } = useTranslations();
  const history = useHistory();
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const subscriptionLeft = moment(userInfo?.subscription?.startDate).add(userInfo?.subscription?.duration, 'months');
  // const isLeftSubscription = moment(subscriptionLeft).isBefore(moment());

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [res, err] = await getConfig('CashForCoinExchangeRate');
    err ? showApiError(err) : setConfig(res ? JSON.parse(res.value) : false);
  };

  return (
    <div css={roomRateContainer(expand, room?.priceDetails?.cryptoPrice > 0)}>
      <p className="title">{getText('flexibleCancelation')}</p>
      <div className="prices-styles">
        <div className="price-container">
          <div>
            {room?.priceDetails?.savingPercentage > 0 && (
              <ColorBatch withoutStartCase type="error" className="color-batch">
                {room?.priceDetails?.savingPercentage}% {getText('off')}
              </ColorBatch>
            )}

            <div className="price">
              <p className="original-price">
                <PriceLabel
                  fiat={Number(
                    add(
                      room?.priceDetails?.cryptoPrice ? mul(room?.priceDetails?.cryptoPrice, config) : 0,
                      add(room?.priceDetails?.price ?? 0, room?.priceDetails?.savingsAmount ?? 0),
                    ),
                  )}
                  fiatOriginCurrency={DEFAULT_CURRENCY.code}
                  fiatDesiredCurrency={selectedCurrency?.code}
                />
              </p>

              {(room?.priceDetails?.price > 0 || room?.priceDetails?.cryptoPrice > 0) && (
                <h4 className="discounted-price">
                  <PriceLabel
                    fiat={Number(room?.priceDetails?.price)}
                    crypto={room?.priceDetails?.cryptoPrice}
                    fiatOriginCurrency={DEFAULT_CURRENCY.code}
                    fiatDesiredCurrency={selectedCurrency?.code}
                  />
                </h4>
              )}
              <p className="perNight"> /{getText('total')}</p>
            </div>
          </div>
          {/*
           // REMOVE TAG HIDE FOR FIRST VERSION
           {(!isAuthenticated || !isLeftSubscription) && ( */}
          <Button
            small
            className="button"
            onClick={() =>
              history.push(
                isAuthenticated
                  ? {
                      pathname: `/hotels/${hotelDetails?.id}/checkout`,
                      state: { room, hotelDetails, filters },
                    }
                  : {
                      pathname: ROUTES.Login,
                      state: {
                        navigateTo: `/hotels/${hotelDetails?.id}/checkout`,
                        item: { room, hotelDetails, filters },
                      },
                    },
              )
            }>
            {getText('bookNow')}
          </Button>
          {/* )} */}
        </div>
        <hr className="horizontal-line" />
        <div className="fee-action">
          {room?.priceDetails?.displayTaxes > 0 && (
            <div className="fee-container">
              <p className="fee">
                +
                <PriceLabel
                  fiat={Number(room?.priceDetails?.displayTaxes)}
                  fiatOriginCurrency={DEFAULT_CURRENCY.code}
                  fiatDesiredCurrency={selectedCurrency?.code}
                />
              </p>
              <span>{getText('totalFee')}</span>
            </div>
          )}
          {room?.rateData?.length > 1 && (
            <Button small type="link" onClick={() => onExpand(!expand)} className="button-view">
              <div className="action-view">
                {!expand ? getText('allOptions', { count: room?.rateData?.length }) : getText('lessOptions')}
                <Icon material iconName={!expand ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} className="icon" />
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomPriceCart;
